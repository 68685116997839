import React from 'react';
import { Component } from 'react';
import './PriceScreen.css';
import { SimpleButton } from '../../Buttons/Simple/SimpleButton';

export default class PriceScreen extends Component {
    private openStoreWebsite() {
        window.open("https://multivoucher.pl/produkt/karta-podarunkowa-mediti/", "_blank");
    }

    private isBeforeOrEqualToTargetDate(targetDateString: string): boolean {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const targetDate = new Date(targetDateString);

        return currentDate <= targetDate;
    }

    public render() {
        let basicPrice = 39;
        let canUserBuyPacket = true;
        let promoLastDay = '2024-12-31';

        return (
            <div className="price-container">
                <div className="row">
                    <div className="col">
                        <div className="title">
                            <p className="title-desc">Sięgnij po pakiet Mediti Premium i uzyskaj dostęp do pełnej oferty Mediti</p>
                            <p className="title-header gold-text">Dostępne subskrypcje</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-4">
                        <div className="price-list-element">
                            <div className="price-list-element-header">
                                <p className="months">1 miesiąc</p>
                                {(() => {
                                    if (this.isBeforeOrEqualToTargetDate(promoLastDay)) {
                                        return (<p className="price"><span className="crossed-text">{basicPrice} zł</span> 29,25 zł</p>);
                                    } else {
                                        return (<p className="price">{basicPrice} zł</p>);
                                    }
                                })()}
                            </div>
                            <div className="price-list-element-desc">
                                <ul>
                                    <li>Nielimitowany dostęp do pełnego katalogu medytacji Mediti</li>
                                    <li>Możliwość korzystania na 2 niezależnych urządzeniach</li>
                                    <li>Dostęp do Mediti na urządzeniach iOS oraz Android</li>
                                    {(() => {
                                    if (this.isBeforeOrEqualToTargetDate(promoLastDay)) {
                                        return (<li><span className="crossed-text">1,30 zł</span> 0,96 zł dziennie</li>);
                                    } else {
                                        return (<li>1,30 zł dziennie</li>);
                                    }
                                })()}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="price-list-element">
                            <div className="price-list-element-header">
                                <p className="months">3 miesiące</p>
                                {(() => {
                                    if (this.isBeforeOrEqualToTargetDate(promoLastDay)) {
                                        return (<p className="price"><span className="crossed-text">99 zł</span> 74,25 zł</p>);
                                    } else {
                                        return (<p className="price"><span className="crossed-text">{basicPrice * 3} zł</span> 99 zł</p>);
                                    }
                                })()}
                            </div>
                            <div className="price-list-element-desc">
                                <ul>
                                    <li>Nielimitowany dostęp do pełnego katalogu medytacji Mediti</li>
                                    <li>Możliwość korzystania na 2 niezależnych urządzeniach</li>
                                    <li>Dostęp do Mediti na urządzeniach iOS oraz Android</li>
                                    {(() => {
                                    if (this.isBeforeOrEqualToTargetDate(promoLastDay)) {
                                        return (<li><span className="crossed-text">1,10 zł</span> 0,83 zł dziennie</li>);
                                    } else {
                                        return (<li>1,10 zł dziennie</li>);
                                    }
                                })()}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="price-list-element">
                            <div className="price-list-element-header">
                                <p className="months">12 miesięcy</p>
                                {(() => {
                                    if (this.isBeforeOrEqualToTargetDate(promoLastDay)) {
                                        return (<p className="price"><span className="crossed-text">320 zł</span> 240 zł</p>);
                                    } else {
                                        return (<p className="price"><span className="crossed-text">{basicPrice * 12} zł</span> 320 zł</p>);
                                    }
                                })()}
                            </div>
                            <div className="price-list-element-desc">
                                <ul>
                                    <li>Nielimitowany dostęp do pełnego katalogu medytacji Mediti</li>
                                    <li>Możliwość korzystania na 2 niezależnych urządzeniach</li>
                                    <li>Dostęp do Mediti na urządzeniach iOS oraz Android</li>
                                    {(() => {
                                    if (this.isBeforeOrEqualToTargetDate(promoLastDay)) {
                                        return (<li><span className="crossed-text">0,88 zł</span> 0,66 zł dziennie</li>);
                                    } else {
                                        return (<li>0,88 zł dziennie</li>);
                                    }
                                })()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="footer">
                            {(() => {
                                if (canUserBuyPacket) {
                                    return (
                                        <div>
                                            <p className="footer-text1">Chcesz uzyskać dostęp do Mediti Premium?</p>
                                            <div className="buy-now">
                                                <SimpleButton
                                                    text="Kup teraz"
                                                    onClick={(e) => this.openStoreWebsite()}
                                                />
                                            </div>
                                        </div>);
                                }
                                else {
                                    return (
                                        <div>
                                            <p className="footer-text1">Już wkrótce kody będą dostępne na www.multivoucher.pl</p>
                                        </div>);
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}